import { Logger } from './Logger';
import { SaveAddress } from './SaveAddress';
import { SaveWorkableItem } from './SaveWorkableItem';

/**
 * mutations
 *
 * @type {{Logger: ((function(*): Promise<{}>)|*), SaveAddress: ((function({}=): Promise<{}>)|*), SaveWorkableItem:
 *   ((function({}=): Promise<{}>)|*)}}
 */
export const mutations = {
  Logger,
  SaveAddress,
  SaveWorkableItem,
};
