import Image from 'next/image';
import React, { useEffect, useState } from 'react';
import { Button } from '@src/components/Button';
import { Card } from '@abyss/web/ui/Card';
import { config } from '@abyss/web/tools/config';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import { isArray, isEmpty, isNull, isUndefined } from 'lodash';
import { Layout } from '@abyss/web/ui/Layout';
import { Link } from '@abyss/web/ui/Link';
import { PageHeader } from '@abyss/web/ui/PageHeader';
import { Sticky } from 'react-sticky';
import { uniq } from 'lodash/array';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { useRoutesContext } from '@src/context/Routes';
import { useUserContext } from '@src/context/User';
import { useVisibilityContext } from '@src/context/Visibility';
import { Visibility } from '@src/components/Visibility';
import { Styles } from './includes/styles';

/**
 * Header
 *
 * Renders the logo and navigation which "sticks" as the user scrolls vertically.
 *
 * @returns {Element}
 * @constructor
 */
export const Header = () => {
  const { displayName } = useUserContext();
  const { visibility: visibilityContext } = useVisibilityContext();

  const router = useRouter();
  const location = router?.getLocation();
  const path = location?.pathname;

  const { currentRoute, currentRoutes } = useRoutesContext();
  const [items, setItems] = useState([]);

  /**
   * Set the current navigation items based on the contextual routes.
   */
  useEffect(() => {
    (() => {
      if (isUndefined(currentRoutes) || isEmpty(currentRoutes) || !isArray(currentRoutes)) {
        return false;
      }

      const routes = [];

      currentRoutes.forEach((primaryRoute) => {
        const primaryItem = {
          title: primaryRoute?.screenTitle,
          href: primaryRoute?.path,
        };

        // Include primary items
        if (
          !currentRoutes.includes(primaryItem) &&
          primaryRoute?.showInNavigation === true &&
          visibilityContext?.components?.[primaryRoute?.accessor]?.status !== 'disabled'
        ) {
          routes.push(primaryItem);
        }
      });

      if (!isEmpty(routes)) {
        const navigationItems = uniq(routes);
        setItems(navigationItems);
      }

      return true;
    })();
  }, [path, currentRoutes, currentRoute, visibilityContext?.components]);

  /**
   * Determines the currently active navigation menu item, based on the current route.
   */
  useEffect(() => {
    if (!isEmpty(currentRoute)) {
      const navigationItems = document.querySelectorAll(
        '#app-header .abyss-page-header-top-nav-menu .abyss-nav-menu-link, #app-header .abyss-page-header-top-nav-menu .abyss-nav-menu-menu-trigger'
      );

      if (!isEmpty(navigationItems)) {
        navigationItems.forEach((navigationItem) => {
          navigationItem.classList.remove('active');
          if (
            currentRoute?.path === navigationItem.pathname ||
            (navigationItem.pathname !== '/' && currentRoute?.path?.includes(navigationItem.pathname)) ||
            navigationItem.innerText === currentRoute?.navigationLabel ||
            navigationItem.innerText === currentRoute?.parent?.navigationLabel
          ) {
            navigationItem.classList.add('active');
          }
        });
      }
    }
  });

  return (
    <ErrorHandler location="src/layouts/default/Header.jsx">
      <Visibility>
        <Styles>
          <Sticky topOffset={40}>
            {({ style, isSticky }) => {
              const pageHeader = document.querySelector('.abyss-page-header-container');

              if (!isNull(pageHeader)) {
                if (isSticky) {
                  pageHeader.classList.add('sticky');
                } else {
                  pageHeader.classList.remove('sticky');
                }
              }

              return (
                <header id="app-header" style={style}>
                  <PageHeader
                    fullWidth
                    topMenuItems={[
                      ...items,
                      ...[
                        {
                          title: (
                            <React.Fragment>
                              <IconSymbol icon="account_circle" /> {displayName}
                            </React.Fragment>
                          ),
                          content: (
                            <Layout.Group alignLayout="right" css={{ width: 'auto' }}>
                              <Card
                                css={{
                                  padding: 'var(--abyss-space-sm)',
                                  minWidth: 250,
                                  boxShadow: '0px 10px 38px -10px rgba(14, 18, 22, 0.35)',
                                  borderColor: 'var(--abyss-colors-gray3)',
                                }}
                              >
                                <Button
                                  before={<IconSymbol icon="logout" />}
                                  href="/logout"
                                  variant="ghost"
                                  css={{ width: '100%' }}
                                >
                                  Logout
                                </Button>
                              </Card>
                            </Layout.Group>
                          ),
                          before: <IconSymbol icon="account_circle" />,
                          after: <IconSymbol icon="keyboard_arrow_down" />,
                        },
                      ],
                    ]}
                    logo={
                      <Link href="/" variant="custom">
                        <Image
                          alt="UHG logo"
                          src="/logo.svg"
                          width="0"
                          height="0"
                          sizes="100vw"
                          style={{ width: '100%', height: 'auto' }}
                          priority
                        />
                      </Link>
                    }
                    logoTitle={config('APP_NAME')}
                  />
                </header>
              );
            }}
          </Sticky>
        </Styles>
      </Visibility>
    </ErrorHandler>
  );
};
