import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { Box } from '@abyss/web/ui/Box';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Grid } from '@abyss/web/ui/Grid';
import { Heading } from '@abyss/web/ui/Heading';
import { ListCountries, ListTerritoryProvinces } from '@src/includes/functions';
import { SelectInput } from '@abyss/web/ui/SelectInput';
import { TextInput } from '@abyss/web/ui/TextInput';
import { isEmpty } from 'lodash';
import fields from '../../../../includes/fields.json';

/**
 * Tab: InternationalAddress
 *
 * Displays international address information about a member's policy.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const InternationalAddress = (props) => {
  const { form, voidFormDisabled } = props;

  const city = form?.getValues('ui[internationalAddress][city]');
  const countryCode = form?.getValues('ui[internationalAddress][countryCode]');
  const isInternational = form?.getValues('ui[isInternational]');
  const stateOrRegion = form?.getValues('ui[internationalAddress][stateOrRegion]');

  /**
   * ListCountries
   *
   * Retrieves a list of countries.
   *
   * @type {{code: *, name: *}[]}
   */
  const Countries = useMemo(() => {
    return ListCountries().filter((country) => {
      return !(isInternational && country?.code === 'US');
    });
  }, [isInternational]);

  /**
   * TerritoriesProvinces
   *
   * Retrieves a list of states.
   *
   * @type {{code: *, name: *}[]}
   */
  const TerritoriesProvinces = useMemo(() => {
    return ListTerritoryProvinces(countryCode);
  }, [countryCode]);

  return (
    <ErrorHandler location="src/routes/private/MemberProfile/components/Modals/CondfComm/components/Address/components/InternationalAddress/InternationalAddress.jsx">
      <Grid className="section first">
        <Box className="box">
          <Heading offset={5}>Address Line 1:</Heading>
        </Box>
        <Grid.Col span={{ xs: 12, lg: 4, xl: 4 }}>
          <TextInput
            {...fields?.internationalAddress?.addressLine1}
            formatter={(value) => {
              return value.replace(/[-`&/\\#,+()$~%.'":*?<>{}@!^_=;\][|]/g, '');
            }}
            isDisabled={voidFormDisabled}
          />
        </Grid.Col>
      </Grid>
      <Grid className="section">
        <Box className="box">
          <Heading offset={5}>Address Line 2:</Heading>
        </Box>
        <Grid.Col span={{ xs: 12, lg: 4, xl: 4 }}>
          <TextInput
            {...fields?.internationalAddress?.addressLine2}
            formatter={(value) => {
              return value.replace(/[-`&/\\#,+()$~%.'":*?<>{}@!^_=;\][|]/g, '');
            }}
            isDisabled={voidFormDisabled}
          />
        </Grid.Col>
      </Grid>
      <Grid className="section">
        <Box className="box">
          <Heading offset={5}>Address Line 3:</Heading>
        </Box>
        <Grid.Col span={{ xs: 12, lg: 3, xl: 3 }}>
          <SelectInput
            {...fields?.internationalAddress?.countryCode}
            isDisabled={!isInternational || voidFormDisabled}
            onChange={() => {}}
            onClear={() => {}}
            options={Countries.map((country) => {
              return {
                value: country?.code,
                label: country?.name,
              };
            })}
          />
        </Grid.Col>
        <Grid.Col span={{ xs: 12, lg: 3, xl: 3 }}>
          <SelectInput
            {...fields?.internationalAddress?.stateOrRegion}
            options={TerritoriesProvinces.map((territoryProvince) => {
              return {
                value: territoryProvince?.code,
                label: territoryProvince?.name,
              };
            })}
            isDisabled={voidFormDisabled || isEmpty(countryCode)}
          />
        </Grid.Col>
        <Grid.Col span={{ xs: 12, lg: 3, xl: 3 }}>
          <TextInput
            {...fields?.internationalAddress?.city}
            isDisabled={voidFormDisabled || isEmpty(countryCode) || isEmpty(stateOrRegion)}
          />
        </Grid.Col>
        <Grid.Col span={{ xs: 12, lg: 3, xl: 3 }}>
          <TextInput
            {...fields?.internationalAddress?.postalCode}
            formatter={(value) => {
              return value.replace(/[-`&/\\#,+()$~%.'":*?<>{}@!^_=;\][|]/g, '');
            }}
            isDisabled={voidFormDisabled || isEmpty(countryCode) || isEmpty(stateOrRegion) || isEmpty(city)}
          />
        </Grid.Col>
      </Grid>
    </ErrorHandler>
  );
};

InternationalAddress.propTypes = {
  form: PropTypes.shape({
    getValues: PropTypes.func,
    setValue: PropTypes.func,
  }),
  voidFormDisabled: PropTypes.bool,
  TerritoriesProvinces: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string,
      name: PropTypes.string,
    })
  ),
};

InternationalAddress.defaultProps = {
  form: {},
  voidFormDisabled: false,
  TerritoriesProvinces: [],
};
