import PropTypes from 'prop-types';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { dayjs } from '@abyss/web/tools/dayjs';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Grid } from '@abyss/web/ui/Grid';
import { isArray, isEmpty, isUndefined } from 'lodash';
import { LoadingSpinner } from '@abyss/web/ui/LoadingSpinner';
import { motion } from 'framer-motion';
import { useApi } from '@src/context/Api';
import { Styles } from '../includes/styles';

/**
 * ExpansionRow
 *
 * Displays the expanded row for the member with additional information.
 *
 * @param row
 * @returns {Element}
 * @constructor
 */
export const ExpansionRow = ({ row: memberData }) => {
  const [additionalAddresses, setAdditionalAddresses] = useState([]);

  const { useApiQuery } = useApi();

  const [GetAddresses, { data: addressData, isLoading: isLoadingAddresses, isFetching: isFetchingAddresses }] =
    useApiQuery('GetAddresses');

  /**
   * Update the additional addresses and current version when the address data changes.
   */
  useEffect(() => {
    if (!isUndefined(addressData?.addresses) && !isEmpty(addressData?.addresses)) {
      setAdditionalAddresses(addressData?.addresses);
    }
  }, [addressData]);

  /**
   * Retrieve additional addresses for the member from the API.
   */
  useLayoutEffect(() => {
    if (!isUndefined(memberData) && !isEmpty(memberData)) {
      if (!isLoadingAddresses && !isFetchingAddresses && isUndefined(addressData)) {
        GetAddresses(memberData);
      }
    }
  }, [addressData, isFetchingAddresses, isLoadingAddresses, memberData]);

  return (
    <ErrorHandler location="src/routes/private/Search/components/Results/components/Table/components/ExpansionRow/ExpansionRow.jsx">
      <Styles>
        <motion.div
          animate={memberData ? 'open' : 'closed'}
          variants={{
            open: { opacity: 1 },
            closed: { opacity: 0 },
          }}
          initial={{ opacity: 0 }}
        >
          {isLoadingAddresses || isFetchingAddresses ? (
            <div style={{ width: '100%', padding: '20px' }}>
              <LoadingSpinner size="$lg" isLoading style={{ textAlign: 'center' }} ariaLoadingLabel="Loading..." />
            </div>
          ) : (
            <div style={{ margin: 'var(--abyss-space-lg) auto', width: '75%' }}>
              {isArray(additionalAddresses) &&
                !isEmpty(additionalAddresses) &&
                additionalAddresses?.map((val) => {
                  const { addresses, confCommEntryId, confComType, ccEffectiveDate, ccEndDate } = val;
                  const address = addresses.find((addr) => {
                    return !addr.isInternational;
                  });
                  return (
                    <Grid key={confCommEntryId}>
                      <Grid.Col span={{ xs: '25%' }}>
                        <p>
                          <strong>Type</strong>
                        </p>
                        <p>{confComType || <React.Fragment>&mdash;</React.Fragment>}</p>
                      </Grid.Col>
                      <Grid.Col span={{ xs: '25%' }}>
                        <p>
                          <strong>Address</strong>
                        </p>
                        <div>
                          {address?.line1 &&
                          address?.city &&
                          address?.stateOrRegion &&
                          address?.countryCode &&
                          address?.postalCode ? (
                            <address>
                              {address?.line1}, {address?.line2}, {address?.line3}
                              <br />
                              {address?.city}, {address?.stateOrRegion}
                              <br />
                              {address?.countryCode}
                              <br />
                              {address?.postalCode}
                            </address>
                          ) : (
                            <React.Fragment>&mdash;</React.Fragment>
                          )}
                        </div>
                      </Grid.Col>
                      <Grid.Col span={{ xs: '50%' }}>
                        <p>
                          <strong>Effective Date</strong>
                        </p>
                        <p>
                          {ccEffectiveDate && ccEndDate ? (
                            <React.Fragment>
                              {dayjs(ccEffectiveDate).format('MM/DD/YYYY')} to {dayjs(ccEndDate).format('MM/DD/YYYY')}
                            </React.Fragment>
                          ) : (
                            <React.Fragment>&mdash;</React.Fragment>
                          )}
                        </p>
                      </Grid.Col>
                    </Grid>
                  );
                })}
            </div>
          )}
        </motion.div>
      </Styles>
    </ErrorHandler>
  );
};

ExpansionRow.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      addressLine: PropTypes.string,
      city: PropTypes.string,
      state: PropTypes.string,
      zipCode: PropTypes.string,
      policyStartDate: PropTypes.string,
      policyEndDate: PropTypes.string,
      cagId: PropTypes.arrayOf(
        PropTypes.shape({
          carrierId: PropTypes.string,
        })
      ),
    }),
  }),
};

ExpansionRow.defaultProps = {
  row: {},
};
