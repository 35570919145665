import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Box } from '@abyss/web/ui/Box';
import { dayjs } from '@abyss/web/tools/dayjs';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Flex } from '@abyss/web/ui/Flex';
import { Grid } from '@abyss/web/ui/Grid';
import { Heading } from '@abyss/web/ui/Heading';
import { Layout } from '@abyss/web/ui/Layout';
import { motion } from 'framer-motion';
import { isEmpty } from 'lodash';
import { Styles } from './includes/styles';
import { WorkQueueButton } from '../Buttons';

/**
 * Details
 *
 * Displays details about a member.
 *
 * @returns {Element}
 * @constructor
 */
export const Details = (props) => {
  const { memberData, showBorder, showName, showIdentifiers, showDemographics, workQueue } = props;

  const [hasWorkQueue, setHasWorkQueue] = useState(false);

  /**
   *
   */
  useEffect(() => {
    let theStatus = false;

    const workableItems = workQueue?.content?.filter((workableItem) => {
      return ['IN_PROGRESS', 'COMPLETE'].includes(workableItem?.status);
    });

    if (!isEmpty(workableItems)) {
      theStatus = true;
    }

    if (theStatus !== hasWorkQueue) {
      setHasWorkQueue(theStatus);
    }
  }, [workQueue]);

  return (
    <ErrorHandler location="src/routes/private/memberData/components/Details/Details.jsx">
      <Styles>
        <motion.div
          animate={memberData ? 'open' : 'closed'}
          variants={{
            open: { opacity: 1 },
            closed: { opacity: 0 },
          }}
          initial={{ opacity: 0 }}
        >
          <Box className={`box row ${showBorder === true ? 'border' : ''}`}>
            <Grid>
              {showName === true && (
                <Grid.Col
                  span={{
                    xs: '100%',
                  }}
                >
                  <Flex direction="row" alignItems="center">
                    <Heading offset={1} color="black">
                      {memberData?.lastName}, {memberData?.firstName}
                    </Heading>
                    {hasWorkQueue === true && (
                      <div
                        style={{
                          marginLeft: 'var(--abyss-space-md)',
                        }}
                      >
                        <WorkQueueButton eid={memberData?.eid} policyNumber={memberData?.policyNumber} />
                      </div>
                    )}
                  </Flex>
                </Grid.Col>
              )}

              {showIdentifiers === true && (
                <React.Fragment>
                  <Grid.Col span={{ xs: '20%' }}>
                    <p>
                      <strong>Policy Number</strong>
                    </p>
                    <p>{memberData?.policyNumber || <React.Fragment>&mdash;</React.Fragment>}</p>
                  </Grid.Col>
                  <Grid.Col span={{ xs: '20%' }}>
                    <p>
                      <strong>Member ID</strong>
                    </p>
                    <p>{memberData?.memberId || <React.Fragment>&mdash;</React.Fragment>}</p>
                  </Grid.Col>
                  <Grid.Col span={{ xs: '20%' }}>
                    <p>
                      <strong>Alt Member ID</strong>
                    </p>
                    <p>{memberData?.memberAltId?.join(', ') || <React.Fragment>&mdash;</React.Fragment>}</p>
                  </Grid.Col>

                  <Grid.Col span={{ xs: '20%' }}>
                    <p>
                      <strong>Enterprise ID (EID)</strong>
                    </p>
                    <p>{memberData?.eid || <React.Fragment>&mdash;</React.Fragment>}</p>
                  </Grid.Col>

                  <Grid.Col span={{ xs: '20%' }}>
                    <p>
                      <strong>CAG ID</strong>
                    </p>
                    {memberData?.cagId?.length > 0 ? (
                      <Layout.Stack grow>
                        <div>
                          <strong>Carrier ID:</strong> {memberData?.cagId?.[0]?.carrierId}
                        </div>
                        <div>
                          <strong>Account ID:</strong> {memberData?.cagId?.[0]?.accountId}
                        </div>
                        <div>
                          <strong>Group ID:</strong> {memberData?.cagId?.[0]?.groupId}
                        </div>
                        <div>
                          <strong>Member ID:</strong> {memberData?.cagId?.[0]?.memberId}
                        </div>
                      </Layout.Stack>
                    ) : (
                      <p>
                        <React.Fragment>&mdash;</React.Fragment>
                      </p>
                    )}
                  </Grid.Col>
                </React.Fragment>
              )}

              {showDemographics === true && (
                <React.Fragment>
                  <Grid.Col span={{ xs: '20%' }}>
                    <p>
                      <strong>DOB</strong>
                    </p>
                    <p>{dayjs(memberData?.dob).format('MM/DD/YYYY') || <React.Fragment>&mdash;</React.Fragment>}</p>
                  </Grid.Col>
                  <Grid.Col span={{ xs: '20%' }}>
                    <p>
                      <strong>Phone Number</strong>
                    </p>
                    <p>{memberData?.phone || <React.Fragment>&mdash;</React.Fragment>}</p>
                  </Grid.Col>
                  <Grid.Col span={{ xs: '20%' }}>
                    <p>
                      <strong>Email</strong>
                    </p>
                    <p>{memberData?.email || <React.Fragment>&mdash;</React.Fragment>}</p>
                  </Grid.Col>
                  <Grid.Col span={{ xs: '20%' }}>
                    <p>
                      <strong>Address</strong>
                    </p>
                    <div>
                      {memberData?.addressLine && memberData?.city && memberData?.state && memberData?.zipCode ? (
                        <address>
                          {memberData?.addressLine}
                          <br />
                          {memberData?.city}, {memberData?.state}
                          <br />
                          {memberData?.zipCode}
                        </address>
                      ) : (
                        <React.Fragment>&mdash;</React.Fragment>
                      )}
                    </div>
                  </Grid.Col>
                </React.Fragment>
              )}
            </Grid>
          </Box>
        </motion.div>
      </Styles>
    </ErrorHandler>
  );
};

Details.propTypes = {
  memberData: PropTypes.shape({
    lastName: PropTypes.string,
    firstName: PropTypes.string,
    policyNumber: PropTypes.string,
    memberId: PropTypes.string,
    memberAltId: PropTypes.arrayOf(PropTypes.string),
    eid: PropTypes.string,
    cagId: PropTypes.arrayOf(
      PropTypes.shape({
        carrierId: PropTypes.string,
        accountId: PropTypes.string,
        groupId: PropTypes.string,
        memberId: PropTypes.string,
      })
    ),
    dob: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string,
    addressLine: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zipCode: PropTypes.string,
  }),
  showBorder: PropTypes.bool,
  showName: PropTypes.bool,
  showIdentifiers: PropTypes.bool,
  showDemographics: PropTypes.bool,
  workQueue: PropTypes.shape({
    content: PropTypes.arrayOf(
      PropTypes.shape({
        status: PropTypes.string,
      })
    ),
  }),
};

Details.defaultProps = {
  memberData: {},
  showBorder: true,
  showName: true,
  showIdentifiers: true,
  showDemographics: true,
  workQueue: {},
};
