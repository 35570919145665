import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { dayjs } from '@abyss/web/tools/dayjs';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { orderBy } from 'lodash';
import { Table as TableComponent } from '@src/components/Table-static';
import { Visibility } from '@src/components/Visibility';
import configuration from './includes/configuration.json';

/**
 * Table: ChangeEvents
 *
 * Displays a list of field history from the workable item from the PROTECT database.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const Table = (props) => {
  const { rows } = props;

  /**
   * renderCellField
   *
   * Displays the field of the workable item within a cell.
   *
   * @param args
   * @returns {*}
   */
  const renderCellField = (args) => {
    const { cell } = args;

    return cell?.value;
  };

  /**
   * renderCellValue
   *
   * Displays the value of the field from the workable item within a cell.
   *
   * @param args
   * @returns {*}
   */
  const renderCellValue = (args) => {
    const { cell, row } = args;

    if (row?.original?.field === 'NOTES') {
      return '';
    }

    return cell?.value;
  };

  /**
   * renderCellLastModified
   *
   * Displays the modified date of the field from the workable item within a cell.
   *
   * @param args
   * @returns {*}
   */
  const renderCellLastModified = (args) => {
    const { cell } = args;

    return dayjs(cell?.value).format('MM/DD/YYYY, HH:mm:ss');
  };

  /**
   * renderCellLastModifiedBy
   *
   * Displays the person who modified the field from the workable item within a cell.
   *
   * @param args
   * @returns {*}
   */
  const renderCellLastModifiedBy = (args) => {
    const { cell } = args;

    return cell?.value;
  };

  /**
   * Columns for table.
   */
  const columns = useMemo(() => {
    return orderBy(configuration?.initialColumns, ['order'], ['asc']).map((item) => {
      const column = item;

      if (column.Header === 'Field') {
        column.Cell = renderCellField;
      }

      if (column.Header === 'Value') {
        column.Cell = renderCellValue;
      }

      if (column.Header === 'Last Modified') {
        column.Cell = renderCellLastModified;
      }

      if (column.Header === 'Last Modified By') {
        column.Cell = renderCellLastModifiedBy;
      }

      return column;
    });
  }, []);

  return (
    <ErrorHandler location="src/routes/private/WorkQueue/screens/View/components/ChangeEvents/components/Table/Table.jsx">
      <Visibility>
        <TableComponent
          {...{
            accessor: 'ChangeEvents',
            columns,
            configuration,
            rows,
          }}
        />
      </Visibility>
    </ErrorHandler>
  );
};

Table.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string,
      value: PropTypes.string,
      lastModified: PropTypes.string,
      lastModifiedBy: PropTypes.string,
    })
  ),
};

Table.defaultProps = {
  rows: [],
};
