import { config } from '@abyss/web/tools/config';
import { Axios } from '@src/context/Api/includes/Axios';

/**
 * SaveAddress
 *
 * Makes a request to the remote API to save an address in the database.
 *
 * @param payload
 * @returns {Promise<{}>}
 * @constructor
 */
export const SaveAddress = async (payload = {}) => {
  try {
    const theMutationKey = payload?.[0];
    const thePayload = payload?.[1];

    const requestArgs = {
      baseURL: config('API_URL'),
      method: 'POST',
      headers: {},
      params: { mutationKey: theMutationKey },
      data: {},
    };

    // Creating an address
    if (thePayload?.mode === 'create') {
      if (thePayload?.newPolicy === true) {
        // This is a new policy for an existing member
        requestArgs.data = {
          ccEntries: [thePayload?.data],
          policyNumber: thePayload?.policyNumber,
        };
        requestArgs.headers['x-api-endpoint'] = `/${thePayload?.eid}/${thePayload?.version}/policies/add`;
        requestArgs.method = 'PUT';
      } else if (thePayload?.newDocument === true) {
        // This is a new policy for a new member
        requestArgs.data = {
          id: thePayload?.eid,
          policies: [
            {
              ccEntries: [thePayload?.data],
              policyNumber: thePayload?.policyNumber,
            },
          ],
          dob: thePayload?.dob,
        };
      } else {
        // Create a new address for an existing policy
        requestArgs.data = thePayload?.data;
        requestArgs.headers[
          'x-api-endpoint'
        ] = `/${thePayload?.eid}/${thePayload?.version}/policies/${thePayload?.policyNumber}/addresses`;
      }
    }

    // Updating an address
    if (thePayload?.mode === 'edit') {
      // Update an existing address for an existing policy
      requestArgs.method = 'PUT';
      requestArgs.data = thePayload?.data;
      requestArgs.headers[
        'x-api-endpoint'
      ] = `/${thePayload?.eid}/${thePayload?.version}/policies/${thePayload?.policyNumber}/addresses/${thePayload?.data?.confCommEntryId}`;
    }

    const remoteResponse = await Axios.request(requestArgs);

    return remoteResponse?.data;
  } catch (error) {
    let theError = error;

    if (!String(config('APP_ENV')).toLowerCase().includes('local')) {
      theError = JSON.stringify(error);
    }

    console.error('src/requests/mutations/SaveAddress.js -> SaveAddress() -> error:', theError);

    throw error;
  }
};
