import React from 'react';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Visibility } from '@src/components/Visibility';
import { DateInput } from '@abyss/web/ui/DateInput';
import { AbyssTheme as themeConfiguration } from '@src/client';
import { Layout } from '@abyss/web/ui/Layout';
import { isUndefined } from 'lodash';
import PropTypes from 'prop-types';
import { Styles } from './includes/styles';

/**
 * DateRange
 *
 * Displays a date range input.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const DateRange = (props = {}) => {
  const {
    defaultEndDate,
    defaultStartDate,
    endDateLabel,
    isClearable,
    isDisabled,
    isStartDisabled,
    isRequired,
    model,
    startDateLabel,
  } = props;

  return (
    <ErrorHandler location="src/components/DateRange/DateRange.jsx">
      <Visibility>
        <Styles>
          <Layout.Group space={themeConfiguration?.theme?.space?.md} alignLayout="left" alignItems="bottom" grow>
            <DateInput
              model={!isUndefined(model) ? `${model}.start` : 'dateRange.start'}
              label={!isUndefined(startDateLabel) ? startDateLabel : 'Date Range'}
              isRequired={isRequired}
              isClearable={isClearable}
              validators={{ required: isRequired }}
              subText=""
              maximumDate={defaultEndDate}
              isDisabled={isDisabled || isStartDisabled}
            />
            <DateInput
              model={!isUndefined(model) ? `${model}.end` : 'dateRange.end'}
              label={!isUndefined(endDateLabel) ? endDateLabel : ''}
              hideLabel={!!isUndefined(endDateLabel)}
              isRequired={isRequired}
              isClearable={isClearable}
              validators={{ required: true }}
              subText=""
              minimumDate={defaultStartDate}
              isDisabled={isDisabled}
            />
          </Layout.Group>
        </Styles>
      </Visibility>
    </ErrorHandler>
  );
};

DateRange.propTypes = {
  defaultEndDate: PropTypes.string,
  defaultStartDate: PropTypes.string,
  endDateLabel: PropTypes.string,
  isClearable: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isStartDisabled: PropTypes.bool,
  isRequired: PropTypes.bool,
  model: PropTypes.string,
  startDateLabel: PropTypes.string,
};

DateRange.defaultProps = {
  defaultEndDate: '',
  defaultStartDate: '',
  endDateLabel: '',
  isClearable: false,
  isDisabled: false,
  isStartDisabled: false,
  isRequired: false,
  model: '',
  startDateLabel: '',
};
