import React, { useEffect, useState } from 'react';
import { Divider } from '@abyss/web/ui/Divider';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Flex } from '@abyss/web/ui/Flex';
import { isEmpty, isUndefined } from 'lodash';
import { Layout } from '@abyss/web/ui/Layout';
import { Text } from '@abyss/web/ui/Text';
import { useRoutesContext } from '@src/context/Routes';
import { Badge } from '@abyss/web/ui/Badge';

/**
 * Filters
 *
 * Displays applied filters for the work queue derived from the route in a removable chip format.
 *
 * @returns {Element}
 * @constructor
 */
export const Filters = () => {
  const { currentRoute } = useRoutesContext();
  const eid = currentRoute?.params?.eid;
  const policyNumber = currentRoute?.params?.policyNumber;

  const [filters, setFilters] = useState([]);

  /**
   * set the filters based on the route params
   */
  useEffect(() => {
    const theFilters = [];

    if (!isUndefined(eid)) {
      theFilters.push({
        label: 'eid',
        value: eid,
      });
    }

    if (!isUndefined(policyNumber)) {
      theFilters.push({
        label: 'policyNumber',
        value: policyNumber,
      });
    }

    if (theFilters !== filters) {
      setFilters(theFilters);
    }
  }, [eid, policyNumber]);

  if (isEmpty(filters)) {
    return null;
  }

  return (
    <ErrorHandler location="src/routes/private/WorkQueue/screens/List/components/Table/components/Header/components/Filters/Filters.jsx">
      <Layout.Group alignLayout="left" alignItems="bottom" css={{ height: '100%' }}>
        <Flex alignItems="center">
          <Text size="sm" fontWeight="bold">
            Filters:
          </Text>
          <Divider orientation="vertical" height={16} margin="$xs" color="" />
          {filters.map((filter) => {
            return (
              <Layout.Group key={filter.label} alignLayout="left" alignItems="center">
                <Badge variant="neutral" rounded>
                  <Layout.Group alignLayout="left" alignItems="center">
                    <Text size="xs" fontWeight="bold">
                      {filter.label}:
                    </Text>
                    <Text size="xs" fontWeight="normal">
                      {filter.value}
                    </Text>
                  </Layout.Group>
                </Badge>
                <Divider orientation="vertical" height={16} width={1} margin="0px" color="" />
              </Layout.Group>
            );
          })}
        </Flex>
      </Layout.Group>
    </ErrorHandler>
  );
};
