import React from 'react';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Grid } from '@abyss/web/ui/Grid';
import { motion } from 'framer-motion';
import { Skeleton as AbyssSkeleton } from '@abyss/web/ui/Skeleton';
import { Heading } from '@abyss/web/ui/Heading';

/**
 * Skeleton
 *
 * Displays a roughed-in idea/placeholder of what the UI should look like while the data is fetching from the API.
 *
 * @returns {JSX.Element}
 * @constructor
 */
export const Skeleton = () => {
  return (
    <ErrorHandler location="src/routes/private/MemberProfile/components/Skeleton/Skeleton.jsx">
      <motion.div
        animate="open"
        variants={{
          open: { opacity: 1 },
          closed: { opacity: 0 },
        }}
        initial={{ opacity: 0 }}
      >
        <Grid>
          <Grid.Col css={{ paddingTop: 'var(--abyss-space-lg)' }} span={{ xs: '100%' }}>
            <Heading offset={0}>
              <AbyssSkeleton height="50px" width="100%" />
            </Heading>
          </Grid.Col>
          <Grid.Col span={{ xs: '100%' }}>
            <AbyssSkeleton height="70px" width="100%" />
          </Grid.Col>
        </Grid>

        <Grid>
          <Grid.Col
            css={{ paddingTop: 'var(--abyss-space-lg)' }}
            span={{
              xs: '50%',
            }}
          >
            <AbyssSkeleton height="1000px" width="100%" />
          </Grid.Col>
          <Grid.Col
            css={{ paddingTop: 'var(--abyss-space-lg)' }}
            span={{
              xs: '50%',
            }}
          >
            <Grid>
              <Grid.Col
                span={{
                  xs: '100%',
                }}
              >
                <AbyssSkeleton height="775px" width="100%" />
              </Grid.Col>

              <Grid.Col
                span={{
                  xs: '100%',
                }}
              >
                <AbyssSkeleton height="200px" width="100%" />
              </Grid.Col>
            </Grid>
          </Grid.Col>
        </Grid>

        <Grid>
          <Grid.Col
            css={{ paddingTop: 'var(--abyss-space-lg)' }}
            span={{
              xs: '100%',
            }}
          >
            <AbyssSkeleton height="325px" width="100%" />
          </Grid.Col>
        </Grid>
      </motion.div>
    </ErrorHandler>
  );
};
