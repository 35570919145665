import { Axios } from '@src/context/Api/includes/Axios';
import { config } from '@abyss/web/tools/config';

/**
 * ListWorkQueue
 *
 * Retrieves a list of work queue items from the remote API via an authenticated request.
 *
 * @param payload
 * @returns {Promise<any>}
 * @constructor
 */
export const ListWorkQueue = async (payload = {}) => {
  const theQueryKey = payload?.[0];
  const thePayload = payload?.[1];

  try {
    const requestArgs = {
      baseURL: config('API_URL'),
      method: 'GET',
      headers: {
        'x-api-endpoint': '/workitem/dashboard',
      },
      params: { ...{ queryKey: theQueryKey }, ...thePayload },
    };

    if (thePayload?.eid && thePayload?.policyNumber) {
      requestArgs.headers['x-api-endpoint'] = `/workitem/eid/${thePayload?.eid}/policy/${thePayload?.policyNumber}`;
    }

    if (thePayload?.sort?.includes('daysInQueue')) {
      requestArgs.params.sort = thePayload.sort.replace('daysInQueue', 'createdDate');
    }

    const remoteResponse = await Axios.request(requestArgs);

    return remoteResponse?.data;
  } catch (error) {
    let theError = error;

    if (!String(config('APP_ENV')).toLowerCase().includes('local')) {
      theError = JSON.stringify(error);
    }

    console.error('src/requests/queries/ListWorkQueue.js -> ListWorkQueue() -> error:', theError);

    throw error;
  }
};
