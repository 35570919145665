import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Alert } from '@abyss/web/ui/Alert';
import { Button } from '@src/components/Button';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Heading } from '@abyss/web/ui/Heading';
import { isEmpty } from 'lodash';
import { Layout } from '@abyss/web/ui/Layout';
import { motion } from 'framer-motion';
import { Text } from '@abyss/web/ui/Text';
import { Styles } from './includes/styles';
import { Table } from './components/Table';

/**
 * Results
 *
 * Displays the search results in a table.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const Results = (props) => {
  const { handleReset, rows, total } = props;

  const [showResults, setShowResults] = useState(true);
  const [showWarning, setShowWarning] = useState(false);

  /**
   * Show warning message if total records exceed 20.
   */
  useEffect(() => {
    if (total >= 20) {
      setShowResults(false);
      setShowWarning(true);
    } else {
      setShowResults(true);
    }
  }, [total]);

  return (
    <ErrorHandler location="src/routes/private/Search/components/Results/Results.jsx">
      <Styles>
        <motion.div
          animate={rows ? 'open' : 'closed'}
          variants={{
            open: { opacity: 1 },
            closed: { opacity: 0 },
          }}
          initial={{ opacity: 0 }}
        >
          <Heading offset={3} css={{ marginBottom: '$sm' }}>
            Search Results
          </Heading>

          {isEmpty(rows) ? (
            <Alert title="Nothing could be found with provided search criteria." variant="info">
              Please refine your search criteria and try again.
            </Alert>
          ) : (
            <React.Fragment>
              {total >= 20 && showWarning && !showResults ? (
                <Alert
                  variant="warning"
                  isVisible={showWarning}
                  onClose={() => {
                    showWarning(false);
                    setShowResults(false);
                  }}
                >
                  <Layout.Stack alignItems="left">
                    <Text fontWeight="bold">
                      Your search exceeded the maximum number of records that we can display (20) and does not include
                      all records.
                      <br />
                      To refine your results, please return to the search screen and add search criteria.
                    </Text>
                    <Layout.Group>
                      <Button
                        variant="outline"
                        onClick={() => {
                          setShowWarning(false);
                          setShowResults(true);
                        }}
                      >
                        Continue to Results
                      </Button>
                      <Button
                        variant="outline"
                        onClick={() => {
                          setShowResults(false);
                          setShowWarning(false);
                          handleReset(true);
                        }}
                      >
                        Refine Search
                      </Button>
                    </Layout.Group>
                  </Layout.Stack>
                </Alert>
              ) : (
                <React.Fragment>{showResults && <Table rows={rows} dataKey="searchResults" />}</React.Fragment>
              )}
            </React.Fragment>
          )}
        </motion.div>
      </Styles>
    </ErrorHandler>
  );
};

Results.propTypes = {
  handleReset: PropTypes.func,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      cagId: PropTypes.arrayOf(
        PropTypes.shape({
          groupId: PropTypes.string,
          memberId: PropTypes.string,
        })
      ),
      memberName: PropTypes.string,
      altMemberId: PropTypes.arrayOf(PropTypes.string),
      primaryAddress: PropTypes.shape({
        address: PropTypes.shape({
          line1: PropTypes.string,
          line2: PropTypes.string,
          line3: PropTypes.string,
          city: PropTypes.string,
          stateOrRegion: PropTypes.string,
          countryCode: PropTypes.string,
          postalCode: PropTypes.string,
        }),
      }),
      dateRanges: PropTypes.arrayOf(
        PropTypes.shape({
          startDate: PropTypes.string,
          endDate: PropTypes.string,
        })
      ),
      dob: PropTypes.string,
      isConfCommAddress: PropTypes.bool,
    })
  ),
  total: PropTypes.number,
};

Results.defaultProps = {
  handleReset: () => {},
  rows: [],
  total: 0,
};
