import { Axios } from '@src/context/Api/includes/Axios';
import { config } from '@abyss/web/tools/config';
import { isEmpty, isUndefined } from 'lodash';
import { sortAddresses } from '@src/includes/functions';

/**
 * GetAddresses
 *
 * Retrieves additional addresses for a member policy.
 *
 * @param payload
 * @returns {Promise<*[]>}
 * @constructor
 */
export const GetAddresses = async (payload = {}) => {
  try {
    const requestArgs = {
      baseURL: config('API_URL'),
      method: 'GET',
      headers: {},
    };

    const theQueryKey = payload?.[0];
    const thePayload = payload?.[1];

    let remoteResponse;
    let addresses = [];
    let version;

    if (thePayload?.isConfCommAddress) {
      requestArgs.params = { ...{ queryKey: theQueryKey } };
      requestArgs.headers['x-api-endpoint'] = `/${thePayload?.eid}/policies/${thePayload?.policyNumber}`;
      remoteResponse = await Axios.request(requestArgs);
    } else if (!isEmpty(thePayload?.additionalPolicies)) {
      // This whole condition is definitely not ideal. it's a workaround for getting the version number
      const confCommPolicy = thePayload?.additionalPolicies?.find((policy) => {
        return policy?.isConfCommAddress;
      });

      if (!isUndefined(confCommPolicy)) {
        requestArgs.params = { ...{ queryKey: theQueryKey } };
        requestArgs.headers['x-api-endpoint'] = `/${thePayload?.eid}/policies/${confCommPolicy?.policyNumber}`;

        const alternatePolicyResponse = await Axios.request(requestArgs);

        if (!isUndefined(alternatePolicyResponse?.data?.data?.version)) {
          version = alternatePolicyResponse?.data?.data?.version;
        }
      }
    }

    if (!isUndefined(remoteResponse?.data?.data?.version)) {
      version = remoteResponse?.data?.data?.version;
    }

    /**
     * Translate the API response to a more usable format for the UI.
     */
    if (!isUndefined(remoteResponse?.data?.data?.policies && thePayload?.isConfCommAddress)) {
      const { policies } = remoteResponse?.data?.data;

      const policyDetails = policies?.find((policy) => {
        return policy?.policyNumber === thePayload?.policyNumber;
      });

      if (!isEmpty(policyDetails?.ccEntries)) {
        addresses = sortAddresses(policyDetails?.ccEntries);
      }
    }

    return { addresses, version };
  } catch (error) {
    let theError = error;

    if (!String(config('APP_ENV')).toLowerCase().includes('local')) {
      theError = JSON.stringify(error);
    }

    console.error('src/requests/queries/GetAddresses.js -> GetAddresses() -> error:', theError);

    throw error;
  }
};
