import React from 'react';
import { Box } from '@abyss/web/ui/Box';
import { Divider } from '@abyss/web/ui/Divider';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Grid } from '@abyss/web/ui/Grid';
import { Heading } from '@abyss/web/ui/Heading';
import { motion } from 'framer-motion';
import { TextInput } from '@abyss/web/ui/TextInput';
import { Styles } from './includes/styles';
import fields from './includes/fields.json';

/**
 * Identifiers
 *
 * Displays a list of fields to filter by identifiers.
 *
 * @returns {Element}
 * @constructor
 */
export const Identifiers = () => {
  return (
    <ErrorHandler location="src/routes/private/Search/components/Filters/components/Identifiers/Identifiers.jsx">
      <Styles>
        <motion.div
          animate="open"
          variants={{
            open: { opacity: 1 },
            closed: { opacity: 0 },
          }}
          initial={{ opacity: 0 }}
        >
          <Box className="box row border">
            <Grid>
              <Grid.Col span={{ xs: '100%' }}>
                <Heading offset={4} color="black">
                  Identifiers
                </Heading>
              </Grid.Col>
            </Grid>
            <Divider margin="$md" color="transparent" />
            <Grid>
              <Grid.Col span="20%">
                <TextInput {...fields?.policyNumber} />
              </Grid.Col>
              <Grid.Col span="20%">
                <TextInput {...fields?.memberId} />
              </Grid.Col>
              <Grid.Col span="20%">
                <TextInput {...fields?.memberAltId} />
              </Grid.Col>
              <Grid.Col span="20%">
                <TextInput {...fields?.enterpriseId} />
              </Grid.Col>
              <Grid.Col span="20%">
                <TextInput {...fields?.cagId} />
              </Grid.Col>
            </Grid>
          </Box>
        </motion.div>
      </Styles>
    </ErrorHandler>
  );
};
