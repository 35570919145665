/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useLayoutEffect, useMemo } from 'react';
import { config } from '@abyss/web/tools/config';
import { isEmpty, isNull, merge } from 'lodash';
import { ListWorkQueue, ViewWorkableItem } from '@src/routes/private/WorkQueue';
import { MemberProfile } from '@src/routes/private/MemberProfile';
import { Search } from '@src/routes/private/Search';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { Outlet, useRoutes } from 'react-router-dom';
import { useRoutesContext } from '@src/context/Routes';
import { useUserContext } from '@src/context/User';

/**
 * Routes
 *
 * Configures the routes for the application within an authenticated state.
 *
 * @returns {*}
 * @constructor
 */
export const Routes = () => {
  const { roles: userRoles } = useUserContext();
  const { currentRoute, setCurrentRoute, currentRoutes, setCurrentRoutes } = useRoutesContext();

  const router = useRouter();
  const location = router?.getLocation();
  const path = location?.pathname;
  const routeParams = router?.getRouteParams();
  const queryString = location?.search;
  const queryParams = Object.fromEntries(new URLSearchParams(queryString));

  /**
   * routerConfiguration
   *
   * Control visibility like this:
   *
   *         visibility: {
   *           disabledEnvironments: ['Stage', 'Production'],
   *           enabledEnvironments: ['Local', 'Development'],
   *         },
   *
   */
  const routerConfiguration = useMemo(() => {
    return [
      {
        element: <Outlet />,
        path: '/',
        screenTitle: config('APP_NAME'),
        navigationLabel: config('APP_NAME'),
        slug: 'root',
        showInNavigation: false,
        showInBreadcrumbs: true,
      },
      {
        element: <Outlet />,
        path: '/work-queue',
        screenTitle: 'Work Queue',
        navigationLabel: 'Work Queue',
        slug: 'work-queue',
        children: [
          {
            element: <ListWorkQueue />,
            path: '/work-queue',
            screenTitle: 'Work Queue',
            navigationLabel: 'Work Queue',
            slug: 'work-queue',
            children: [],
            showInNavigation: false,
            showInBreadcrumbs: false,
          },
          {
            element: <ViewWorkableItem />,
            path: '/work-queue/item',
            screenTitle: 'Workable Item',
            navigationLabel: 'Workable Item',
            slug: 'workable-item',
            children: [
              {
                element: <ViewWorkableItem />,
                path: '/work-queue/item/:workableItemId',
                screenTitle: 'Workable Item',
                navigationLabel: 'Workable Item',
                slug: 'workable-item',
                children: [],
                showInNavigation: false,
                showInBreadcrumbs: true,
                exact: true,
              },
            ],
            showInNavigation: false,
            showInBreadcrumbs: false,
          },
          {
            element: <ListWorkQueue />,
            path: '/work-queue/:eid',
            screenTitle: 'Work Queue',
            navigationLabel: 'Work Queue',
            slug: 'work-queue',
            children: [
              {
                element: <ListWorkQueue />,
                path: '/work-queue/:eid/:policyNumber',
                screenTitle: 'Work Queue',
                navigationLabel: 'Work Queue',
                slug: 'work-queue',
                children: [],
                showInNavigation: false,
                showInBreadcrumbs: false,
              },
            ],
            showInNavigation: false,
            showInBreadcrumbs: false,
          },
        ],
        showInNavigation: true,
        showInBreadcrumbs: true,
      },
      {
        element: <Search />,
        path: '/search',
        screenTitle: 'Search',
        navigationLabel: 'Search',
        slug: 'search',
        children: [],
        showInNavigation: true,
        showInBreadcrumbs: true,
      },
      {
        element: <Search />,
        path: '/search/:key',
        screenTitle: 'Search',
        navigationLabel: 'Search',
        slug: 'search',
        children: [],
        showInNavigation: false,
        showInBreadcrumbs: true,
      },
      {
        element: <MemberProfile />,
        path: '/profile',
        screenTitle: 'Member Profile',
        navigationLabel: 'Member Profile',
        slug: 'view-member-profile',
        children: [
          {
            element: <MemberProfile />,
            path: '/profile/:eid',
            screenTitle: 'Member Profile',
            navigationLabel: 'Member Profile',
            slug: 'view-member-profile',
            children: [],
            showInNavigation: false,
            showInBreadcrumbs: false,
          },
          {
            element: <MemberProfile />,
            path: '/profile/:eid/:policyNumber',
            screenTitle: 'Member Profile',
            navigationLabel: 'Member Profile',
            slug: 'view-member-profile',
            children: [],
            showInNavigation: false,
            showInBreadcrumbs: false,
          },
        ],
        showInNavigation: false,
        showInBreadcrumbs: true,
      },
    ];
  }, [userRoles]);

  /**
   * Handle Redirects
   */
  useEffect(() => {
    (() => {
      if (path === '/') {
        return router.navigate('/search');
      }
      return false;
    })();
  }, [path]);

  /**
   * getTertiaryRoutes
   *
   * @param routes
   * @param secondaryRoute
   * @param secondaryIndex
   * @param primaryIndex
   * @returns {{theRoute: {}, theRoutes: *[]}}
   */
  const getTertiaryRoutes = (routes = [], secondaryRoute = {}, secondaryIndex = 0, primaryIndex = 0) => {
    const theRoutes = routes;
    let theRoute = {};

    secondaryRoute?.children.forEach((route, index) => {
      const match = router.matchPath(
        {
          path: route.path,
          exact: false,
          strict: false,
        },
        path
      );

      if (!isNull(match)) {
        theRoutes[primaryIndex].current = false;
        theRoutes[primaryIndex].inUrl = true;
        theRoutes[primaryIndex].children[secondaryIndex].current = false;
        theRoutes[primaryIndex].children[secondaryIndex].inUrl = true;
        theRoutes[primaryIndex].children[secondaryIndex].children[index].current = true;
        theRoutes[primaryIndex].children[secondaryIndex].children[index].inUrl = true;

        if (isEmpty(theRoute)) {
          theRoute = {
            ...route,
            ...match,
            ...{
              routeParams,
              queryString,
              queryParams,
              parent: secondaryRoute,
            },
          };
        }
      }
    });

    return { theRoute, theRoutes };
  };

  /**
   * getSecondaryRoutes
   *
   * @param routes
   * @param primaryRoute
   * @param primaryIndex
   * @returns {{theRoute: {}, theRoutes: *[]}}
   */
  const getSecondaryRoutes = (routes = [], primaryRoute = {}, primaryIndex = 0) => {
    let theRoutes = routes;
    let theRoute = {};

    primaryRoute?.children.forEach((route, index) => {
      const match = router.matchPath(
        {
          path: route.path,
          exact: true,
          strict: false,
        },
        path
      );

      if (!isNull(match)) {
        theRoutes[primaryIndex].current = false;
        theRoutes[primaryIndex].inUrl = true;
        theRoutes[primaryIndex].children[index].current = true;
        theRoutes[primaryIndex].children[index].inUrl = true;

        if (isEmpty(theRoute)) {
          theRoute = {
            ...route,
            ...match,
            ...{
              routeParams,
              queryString,
              queryParams,
              parent: primaryRoute,
            },
          };
        }
      } else if (!isEmpty(route?.children)) {
        const tertiaryRoutes = getTertiaryRoutes(theRoutes, route, index, primaryIndex);
        theRoutes = merge([], theRoutes, tertiaryRoutes.theRoutes);
        theRoute = merge({}, theRoute, tertiaryRoutes.theRoute);
      }
    });

    return { theRoute, theRoutes };
  };

  /**
   * getPrimaryRoutes
   *
   * @param routes
   * @returns {{theRoute: {}, theRoutes: *[]}}
   */
  const getPrimaryRoutes = (routes = []) => {
    let theRoutes = routes;
    let theRoute = {};

    routes.forEach((route, index) => {
      const match = router.matchPath(
        {
          path: route.path,
          exact: true,
          strict: false,
        },
        path
      );

      if (!isNull(match)) {
        theRoutes[index].current = true;
        theRoutes[index].inUrl = true;

        if (isEmpty(theRoute)) {
          theRoute = {
            ...route,
            ...match,
            ...{
              routeParams,
              queryString,
              queryParams,
              parent: null,
            },
          };
        }
      } else if (!isEmpty(route?.children)) {
        const secondaryRoutes = getSecondaryRoutes(theRoutes, route, index);
        theRoutes = merge([], theRoutes, secondaryRoutes.theRoutes);
        theRoute = merge({}, theRoute, secondaryRoutes.theRoute);
      }
    });

    return { theRoute, theRoutes };
  };

  /**
   * getRoutes
   *
   * @returns {{theRoute: {}, theRoutes: *[]}}
   */
  const getRoutes = () => {
    return getPrimaryRoutes(routerConfiguration);
  };

  /**
   * Determines the current route(s) based on the URL.
   */
  useLayoutEffect(() => {
    const { theRoute, theRoutes } = getRoutes();

    if (theRoute !== currentRoute) {
      setCurrentRoute(theRoute);
    }

    if (theRoutes !== currentRoutes) {
      setCurrentRoutes(theRoutes);
    }
  }, [path, routerConfiguration]);

  return useRoutes(routerConfiguration);
};
