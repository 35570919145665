import { config } from '@abyss/web/tools/config';
import { Axios } from '@src/context/Api/includes/Axios';

/**
 * SaveWorkableItem
 *
 * Makes a request to the remote API to save workable item in the database.
 *
 * @param payload
 * @returns {Promise<{}>}
 * @constructor
 */
export const SaveWorkableItem = async (payload = {}) => {
  try {
    const theMutationKey = payload?.[0];
    const thePayload = payload?.[1];

    const requestArgs = {
      baseURL: config('API_URL'),
      method: 'PUT',
      headers: {
        'x-api-endpoint': `/workitem/${thePayload?.workableItemId}`,
      },
      params: { mutationKey: theMutationKey },
      data: {
        notes: thePayload?.notes,
        status: thePayload?.status,
      },
    };

    const remoteResponse = await Axios.request(requestArgs);

    return remoteResponse?.data;
  } catch (error) {
    let theError = error;

    if (!String(config('APP_ENV')).toLowerCase().includes('local')) {
      theError = JSON.stringify(error);
    }

    console.error('src/requests/mutations/SaveWorkableItem.js -> SaveWorkableItem() -> error:', theError);

    throw error;
  }
};
