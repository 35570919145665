import { styled } from '@abyss/web/tools/styled';

export const Styles = styled('div', {
  '.box': {
    backgroundColor: 'var(--abyss-colors-gray1)',
    marginTop: 'var(--abyss-space-md)',
    marginBottom: 'var(--abyss-space-lg)',
    paddingBottom: 'var(--abyss-space-lg)',
    paddingTop: 'var(--abyss-space-lg)',
    width: 'auto',
    wordWrap: 'break-word',
  },
  '.border': {
    borderTop: '1px solid var(--abyss-colors-gray3)',
    borderBottom: '1px solid var(--abyss-colors-gray3)',
  },
  '.row': {
    marginLeft: 'calc(var(--abyss-space-md) * -1)',
    marginRight: 'calc(var(--abyss-space-md) * -1)',
  },
});
