import { Tabs } from '@abyss/web/ui/Tabs';
import React, { useEffect, useMemo, useState } from 'react';
import { ErrorHandler } from '@src/components/ErrorHandler';
import PropTypes from 'prop-types';
import { isEmpty, omitBy } from 'lodash';
import { UsAddress } from './components/UsAddress';
import { InternationalAddress } from './components/InternationalAddress';
import fields from '../../includes/fields.json';

/**
 * Address
 *
 * Displays address information about a member's policy.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const Address = (props) => {
  const { form, isAddressValid, mode, setIsAddressValid, voidFormDisabled } = props;

  const allValues = form?.getValues();
  const isInternational = form?.getValues('ui[isInternational]');

  const [activeAddressTab, setActiveAddressTab] = useState(0);

  /**
   * Set default US address values when the address is international.
   */
  useEffect(() => {
    if (mode === 'create') {
      if (isInternational) {
        form.setValue('ui[usAddress][addressLine1]', 'ATTN International PROTECT');
        form.setValue('ui[usAddress][addressLine2]', 'PO Box 46701');
        form.setValue('ui[usAddress][city]', 'Greensboro');
        form.setValue('ui[usAddress][postalCode]', '27420');
        form.setValue('ui[usAddress][stateOrRegion]', 'NC');
      } else {
        form.setValue('ui[usAddress][addressLine1]', '');
        form.setValue('ui[usAddress][addressLine2]', '');
        form.setValue('ui[usAddress][city]', '');
        form.setValue('ui[usAddress][postalCode]', '');
        form.setValue('ui[usAddress][stateOrRegion]', '');
      }
    }
  }, [isInternational]);

  /**
   * toggle the active tab based on type of address
   */
  useEffect(() => {
    if (isInternational) {
      setActiveAddressTab(1);
    } else {
      setActiveAddressTab(0);
    }
  }, [isInternational]);

  /**
   * Tabs
   *
   * @type {[{label: string, key: string, content: ((function(*): Element)|*), props: {}},{label: string, key: string,
   *   content: ((function(*): Element)|*), props: {className: (string), isDisabled: boolean}}]}
   */
  const tabs = useMemo(() => {
    return [
      {
        key: 'UsAddress',
        label: 'US Address',
        content: UsAddress,
        props: {},
      },
      {
        key: 'InternationalAddress',
        label: 'International Address',
        content: InternationalAddress,
        props: {
          isDisabled: !isInternational,
          className: !isInternational ? 'isDisabled' : '',
        },
      },
    ];
  }, [isInternational]);

  /**
   * Address Validation
   */
  useEffect(() => {
    const errors = {
      usAddress: {},
      internationalAddress: {},
    };
    if (allValues && allValues?.usAddress) {
      // US Address Validation
      Object.keys(allValues?.usAddress).forEach((key) => {
        if (fields?.usAddress?.[key]?.validators?.required === true) {
          const value = allValues?.usAddress[key];
          errors.usAddress[key] = isEmpty(value);
        }
      });

      errors.usAddress = omitBy(errors.usAddress, (value) => {
        return value === false;
      });
    }

    if (allValues && allValues?.internationalAddress && isInternational) {
      // International Address Validation
      Object.keys(allValues?.internationalAddress).forEach((key) => {
        if (fields?.internationalAddress?.[key]?.validators?.required === true) {
          const value = allValues?.internationalAddress[key];
          errors.internationalAddress[key] = isEmpty(value);
        }
      });

      errors.internationalAddress = omitBy(errors.internationalAddress, (value) => {
        return value === false;
      });
    }

    const hasErrors = !isEmpty(errors?.usAddress) || !isEmpty(errors?.internationalAddress);

    if (hasErrors) {
      setIsAddressValid(false);
    } else {
      setIsAddressValid(true);
    }
  }, [allValues, activeAddressTab, isInternational]);

  return (
    <ErrorHandler location="src/routes/private/MemberProfile/components/Modals/CondfComm/Address/Address.jsx">
      <Tabs size="sm" active={activeAddressTab} onTabChange={setActiveAddressTab} grow={false}>
        {tabs.map((tab) => {
          const TabComponent = tab?.content;

          return (
            <Tabs.Tab key={`address-tabs-${tab.key}`} label={tab.label} {...tab?.props}>
              <TabComponent
                activeAddressTab={activeAddressTab}
                form={form}
                isAddressValid={isAddressValid}
                setActiveAddressTab={setActiveAddressTab}
                voidFormDisabled={voidFormDisabled}
              />
            </Tabs.Tab>
          );
        })}
      </Tabs>
    </ErrorHandler>
  );
};

Address.propTypes = {
  form: PropTypes.shape({
    control: PropTypes.shape({
      setValue: PropTypes.func,
      getValues: PropTypes.func,
    }),
    getValues: PropTypes.func,
    setValue: PropTypes.func,
  }),
  defaultValues: PropTypes.shape({
    address1: PropTypes.string,
    address2: PropTypes.string,
    city: PropTypes.string,
    countryCode: PropTypes.string,
    postalCode: PropTypes.string,
    stateOrRegion: PropTypes.string,
  }),
  mode: PropTypes.string,
  isAddressValid: PropTypes.bool,
  setIsAddressValid: PropTypes.func,
  voidFormDisabled: PropTypes.bool,
};

Address.defaultProps = {
  form: {},
  defaultValues: {},
  mode: 'create',
  isAddressValid: false,
  setIsAddressValid: () => {},
  voidFormDisabled: false,
};
