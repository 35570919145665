import { styled } from '@abyss/web/tools/styled';

export const Styles = styled('div', {
  'header#app-header': {
    backgroundColor: '#ffffff',
    zIndex: '9999',
    '.abyss-page-header-container.sticky': {
      height: '52px',
      '.abyss-page-header-vertical-rule': {
        height: '30px',
      },
    },
    '.abyss-brandmark': {
      filter: 'opacity(1.0) drop-shadow(0 0 0 var(--abyss-colors-black))',
    },
    '.abyss-page-header-top-nav-menu': {
      background: 'transparent',
      boxShadow: 'none',
      '&.abyss-nav-menu-viewport': {
        overflow: 'unset',
      },
      '.active': {
        color: 'var(--abyss-colors-interactive1) !important',
        fontWeight: 'var(--abyss-fontWeights-bold) !important',
      },
      'a.abyss-nav-menu-link, button.abyss-page-header-top-nav-menu, .abyss-nav-menu-item-title': {
        fontSize: 'var(--abyss-fontSizes-md)',
        fontWeight: 'var(--abyss-fontWeights-normal)',
        letterSpacing: '0',
        textDecoration: 'none',
        textTransform: 'none',
        color: 'var(--abyss-colors-black)',
        transition: 'color .3s ease-in-out',
        '&:hover': {
          background: 'transparent',
          color: 'var(--abyss-colors-interactive1)',
          textDecorationColor: 'transparent',
          '&.abyss-icon': {
            '&:hover': {
              color: 'var(--abyss-colors-interactive1) !important',
            },
          },
        },
      },
      'button.abyss-page-header-top-nav-menu': {
        paddingRight: '0px',
        '.abyss-icon': {
          color: 'var(--abyss-colors-black)',
          fill: 'var(--abyss-colors-black)',
        },
        '&[data-state=open]': {
          background: 'transparent',
          color: 'var(--abyss-colors-interactive1)',
          '.abyss-icon': {
            color: 'var(--abyss-colors-interactive1) !important',
            fill: 'var(--abyss-colors-interactive1) !important',
          },
        },
        '&:hover': {
          '.abyss-icon': {
            color: 'var(--abyss-colors-interactive1) !important',
            fill: 'var(--abyss-colors-interactive1) !important',
          },
        },
      },
      '.abyss-nav-menu-columns-root': {
        padding: '0px',
        '.abyss-nav-menu-link': {
          width: '100%',
        },
      },
    },
  },
});
