import React from 'react';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Layout } from '@abyss/web/ui/Layout';
import { Filters } from './components/Filters';

/**
 * Header
 *
 * @TODO Needs description
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const Header = () => {
  return (
    <ErrorHandler location="src/routes/private/WorkQueue/screens/List/components/Table/components/Header/Header.jsx">
      <Layout.Group alignLayout="left" alignItems="bottom" css={{ height: '100%' }}>
        <Filters />
      </Layout.Group>
    </ErrorHandler>
  );
};
