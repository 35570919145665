/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from 'react';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { useRoutesContext } from '@src/context/Routes';
import { Heading } from '@abyss/web/ui/Heading';
import { useApi } from '@src/context/Api';
import { Grid } from '@abyss/web/ui/Grid';
import { isEmpty, isUndefined } from 'lodash';
import { Table } from './components/Table';

/**
 * List
 *
 * Provides the user with a screen listing the existing work queue items.
 *
 * @returns {Element}
 * @constructor
 */
export const List = () => {
  const defaultRequestArgs = {
    page: 0,
    size: 25,
    sort: 'lastModifiedDate,desc',
  };
  const [requestArgs, setRequestArgs] = useState({});

  const { useApiQuery } = useApi();

  const [ListWorkQueue, { data, isLoading, isFetching, error }] = useApiQuery('ListWorkQueue');

  const { currentRoute } = useRoutesContext();
  const eid = currentRoute?.params?.eid;
  const policyNumber = currentRoute?.params?.policyNumber;
  const status = currentRoute?.params?.status;

  /**
   * Set the request arguments for the API query to filter by status.
   */
  useEffect(() => {
    if (!isUndefined(currentRoute) && !isEmpty(currentRoute)) {
      const theRequestArgs = defaultRequestArgs;

      if (!isUndefined(status) && status !== 'all') {
        theRequestArgs.statuses = [status];
      }

      if (!isUndefined(eid)) {
        theRequestArgs.eid = eid;
      }

      if (!isUndefined(policyNumber)) {
        theRequestArgs.policyNumber = policyNumber;
      }

      if (theRequestArgs !== requestArgs) {
        setRequestArgs(theRequestArgs);
      }
    }
  }, [status, eid, policyNumber, data, currentRoute]);

  return (
    <ErrorHandler location="src/routes/private/WorkQueue/screens/List.jsx">
      <Grid>
        <Grid.Col
          span={{
            xs: '100%',
          }}
        >
          <Heading offset={0}>Work Queue</Heading>
        </Grid.Col>
        <Grid.Col
          span={{
            xs: '100%',
          }}
        >
          {!isEmpty(requestArgs) && (
            <Table
              error={error}
              isLoading={isLoading || isFetching}
              requestArgs={requestArgs}
              requestFunction={ListWorkQueue}
              requestKey="ListWorkQueue"
              rows={data?.content || []}
              totalPages={data?.totalPages || 1}
              totalRecords={data?.totalElements || 0}
            />
          )}
        </Grid.Col>
      </Grid>
    </ErrorHandler>
  );
};
