import PropTypes from 'prop-types';
import React from 'react';
import { AbyssTheme as themeConfiguration } from '@src/client';
import { Flex } from '@abyss/web/ui/Flex';
import { isEmpty, isUndefined } from 'lodash';
import { Label } from '@abyss/web/ui/Label';
import { LoadingSpinner } from '@abyss/web/ui/LoadingSpinner';
import { Text } from '@abyss/web/ui/Text';

/**
 * Loader
 *
 * Reusable loader component
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const Loader = (props) => {
  const { height, message, verticalAlignment, width } = props;

  const css = {
    position: 'relative',
    background: 'var(--abyss-colors-white)',
  };

  if (!isUndefined(height)) {
    css.height = '100vh !important';
  }

  if (!isUndefined(width)) {
    css.width = '100vw !important';
  }

  return (
    <Flex
      justify={verticalAlignment || 'center'}
      alignItems="center"
      alignContent="center"
      direction="column"
      css={css}
    >
      <Flex justify="center" alignItems="center" alignContent="center">
        <LoadingSpinner size="$lg" isLoading ariaLoadingLabel="Loading..." />
        <Flex
          css={{ marginLeft: themeConfiguration?.theme?.space?.lg }}
          alignItems="flex-start"
          alignContent="flex-start"
          direction="column"
        >
          <Label
            data-testid="loader-container-abyss-flex-root"
            className="xs-margin-bottom"
            size="$lg"
            css={{ marginBottom: themeConfiguration?.theme?.space?.xs }}
          >
            Loading...
          </Label>
          <Text size="$sm">{!isEmpty(message) ? message : 'Your request is being processed.'}</Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

Loader.propTypes = {
  height: PropTypes.string,
  message: PropTypes.string,
  verticalAlignment: PropTypes.string,
  width: PropTypes.string,
};

Loader.defaultProps = {
  height: '',
  message: '',
  verticalAlignment: '',
  width: '',
};
